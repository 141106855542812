html,
body {
  margin: 0;
}

#App {
  font-family: sans-serif;
  height: 100vh;
}



.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 2rem;
  font-size: 2rem;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: rgb(255, 255, 255);
}

.bm-burger-button {
  
  position: absolute;
  width: 40px;
  height: 30px;
  right: 15px;
  top: 50px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
  opacity: 0.8;
  
}

@media (max-width: 1138px) {
    .bm-burger-button {

        top: 70px
    }
}

@media (max-width: 768px) {
    .bm-burger-button {

        top: 50px
    }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;

  
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: rgba(29,26,26, 1);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  cursor: pointer;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #464001;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media (min-width: 1138px) {
  .bm-burger-button {
    display: none;
  }
}